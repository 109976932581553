import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { sort, prop, descend, filter, propSatisfies, lt } from 'ramda';
import {
  Grid,
  Typography,
  Box,
  Divider,
  Chip,
  Avatar,
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import ThumbnailItem from '../../components/ThumbnailItem';
import LiveStreamSnippet from '../../components/LiveStreamSnippet';
import SeriesSnippet from '../../components/SeriesSnippet';
import PlaylistsSnippet from '../../components/PlaylistsSnippet';

import { items } from '../../utils';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

export const query = graphql`
  query {
    api {
      speakers {
        edges {
          node {
            id
            name
            slug
            imageAvatarChip
            sermonCount
          }
        }
      }
    }
  }
`;

const Page = ({ data, pageContext }) => {
  const { sermons, pageNum, totalPages } = pageContext;

  const orderedSpeakers = filter(
    propSatisfies(lt(0), 'sermonCount'),
    sort(descend(prop('sermonCount')), items(data.api.speakers))
  );

  return (
    <Layout>
      <SEO title="Media" />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Media</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          {sermons.map(({ id, title, speakers, date, youtubeId, slug }) => (
            <ThumbnailItem
              key={id}
              title={title}
              subtext={
                speakers &&
                speakers.edges.map(({ node }) => node.name).join(', ')
              }
              timestamp={date}
              image={`https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`}
              to={`/media/sermons/${slug}`}
            />
          ))}

          <Box mt={6} mb={4}>
            <Pagination
              size="large"
              page={pageNum}
              count={totalPages}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  component={Link}
                  to={
                    item.page === 1 ? '/media' : `/media/sermons/${item.page}`
                  }
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box mb={6}>
            <LiveStreamSnippet />
          </Box>

          <Box mb={6}>
            <SeriesSnippet />
          </Box>

          <Box mb={6}>
            <PlaylistsSnippet />
          </Box>

          <Box mb={4}>
            <Typography variant="h4">Speakers</Typography>
          </Box>
          {orderedSpeakers.map(({ id, name, slug, imageAvatarChip }) => (
            <Box key={id} mb={1}>
              <Chip
                clickable
                component={Link}
                to={`/speakers/${slug}`}
                avatar={
                  <Avatar alt={name} src={imageAvatarChip}>
                    {name
                      .split(' ')
                      .map((w) => w.charAt(0))
                      .join('')}
                  </Avatar>
                }
                label={name}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Page;
