import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Shiitake from 'shiitake';

import RelativeMoment from './RelativeMoment';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: 'normal',
    overflow: 'visible',
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  imageWrapper: {
    display: 'block',
    position: 'relative',
    margin: 0,
    left: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
    [theme.breakpoints.up('sm')]: {
      left: 0,
      width: '100%',
    },
  },
  image: ({ image }) => ({
    width: '100%',
    paddingBottom: '56.25%',
    backgroundColor: '#000',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    textIndent: -10000,
  }),
  content: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  timestamp: {
    color: theme.palette.grey[500],
  },
  titleTooltip: ({ titleTrunc }) => ({
    display: titleTrunc ? 'block' : 'none',
  }),
  subtextTooltip: ({ subtextTrunc }) => ({
    display: subtextTrunc ? 'block' : 'none',
  }),
}));

const ThumbnailItem = ({ title, subtext, timestamp, image, to }) => {
  const [titleTrunc, setTitleTrunc] = useState(false);
  const [subtextTrunc, setSubtextTrunc] = useState(false);

  const classes = useStyles({ image, titleTrunc, subtextTrunc });

  return (
    <MenuItem component={Link} to={to} className={classes.menuItem}>
      <Box width="100%" py={1}>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={5}>
            <div className={classes.imageWrapper}>
              <div className={classes.image} />
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.content}>
              <Tooltip
                title={title}
                classes={{ tooltip: classes.titleTooltip }}
              >
                <Typography variant="h5">
                  <Shiitake lines={1} onTruncationChange={setTitleTrunc}>
                    {title}
                  </Shiitake>
                </Typography>
              </Tooltip>
              {subtext && (
                <Tooltip
                  title={subtext}
                  classes={{ tooltip: classes.subtextTooltip }}
                >
                  <Typography variant="body2" component="div">
                    <Shiitake lines={1} onTruncationChange={setSubtextTrunc}>
                      {subtext}
                    </Shiitake>
                  </Typography>
                </Tooltip>
              )}
              {timestamp && (
                <Typography variant="body2" className={classes.timestamp}>
                  <RelativeMoment>{timestamp}</RelativeMoment>
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </MenuItem>
  );
};

ThumbnailItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  timestamp: PropTypes.string,
  image: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

ThumbnailItem.defaultProps = {
  subtext: null,
  timestamp: null,
};

export default ThumbnailItem;
