import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import ReactMoment from 'react-moment';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { items } from '../utils';
import CoverItem from './CoverItem';

const SeriesSnippet = () => {
  const { api } = useStaticQuery(
    graphql`
      query {
        api {
          serieses(orderBy: "-end_date", first: 3) {
            edges {
              node {
                id
                imageThumb
                title
                slug
                startDate
                endDate
              }
            }
          }
        }
      }
    `
  );

  const { serieses } = api;

  return (
    <>
      <Box mb={4}>
        <Typography variant="h4">Series</Typography>
      </Box>

      <Box mb={4}>
        <MenuList style={{ padding: 0 }}>
          {items(serieses).map(
            ({ id, title, startDate, endDate, imageThumb, slug }) => (
              <Box key={id} mb={2}>
                <CoverItem
                  narrow
                  title={title}
                  subtext={
                    <>
                      <ReactMoment format="MMMM D">{startDate}</ReactMoment>{' '}
                      &mdash;{' '}
                      <ReactMoment format="MMMM D, YYYY">{endDate}</ReactMoment>
                    </>
                  }
                  image={imageThumb}
                  to={`/media/series/${slug}`}
                />
              </Box>
            )
          )}
        </MenuList>
      </Box>

      <Button
        component={Link}
        to="/media/series"
        color="secondary"
        endIcon={<ChevronRightIcon />}
      >
        See all
      </Button>
    </>
  );
};

export default SeriesSnippet;
