import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

import { items } from '../utils';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingLeft: theme.spacing(3),
  },
}));

const PlaylistsSnippet = () => {
  const classes = useStyles();

  const { api } = useStaticQuery(
    graphql`
      query {
        api {
          playlists(orderBy: "-created_at") {
            edges {
              node {
                id
                title
                slug
              }
            }
          }
        }
      }
    `
  );

  const { playlists } = api;

  return (
    <>
      <Box mb={4}>
        <Typography variant="h4">Playlists</Typography>
      </Box>

      <ul className={classes.list}>
        {items(playlists).map((playlist) => (
          <li key={playlist.id}>
            <MuiLink component={Link} to={`/media/playlists/${playlist.slug}`}>
              {playlist.title}
            </MuiLink>
          </li>
        ))}
      </ul>
    </>
  );
};

export default PlaylistsSnippet;
